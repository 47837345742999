import React from 'react';
import {
  Container, styled, Typography, Box, Link,
} from '@mui/material';
import TypographyHeader from '../../components/layout/TypographyHeader';

const Paragraph = styled(Typography)(() => ({
  marginBottom: '2rem',
  textAlign: 'left',
}));

const ContactInfoHeader = styled(TypographyHeader)(() => ({
  marginBottom: 0,
  marginTop: '8px',
}));

const ContactInfoField = styled(Typography)({
  paddingLeft: '3rem',
});

function AboutPage() {
  return (
    <Box sx={{
      width: '100%', display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center',
    }}
    >
      <Container maxWidth="md">
        <Box sx={{
          marginBottom: '4rem', backgroundColor: 'primary.main', borderRadius: '8px', boxShadow: '0px 1px 10px rgba(1,1,1,0.15)', padding: '1rem',
        }}
        >
          <TypographyHeader variant="h3">
            About SNiC
          </TypographyHeader>
          <Paragraph variant="body1">
            SNiC is an acronym for Stichting Nationaal Informatica Congres
            (Foundation National Computer Science Conference).
            This organisation was established in
            2004 with the purpose of stimulating interest in
            knowledge of information and communication technology,
            and to promote contact between students and the business world.
            9 study associations from 7 different cities are affiliated with SNiC.
            Every year,
            a conference with an IT-related subject is organised and for
            each conference, one of those 9 study associations is chosen to
            take care of the organisation.
            This year&apos;s SNiC was organised on November 27th, 2024 by students from
            {' '}
            <Link href="https://www.svia.nl" variant="inherit" sx={{ color: '#304ba3' }}>
              <b>via</b>
            </Link>
            , the study association for Information Sciences at the University of Amsterdam.
          </Paragraph>
          <Paragraph variant="body1">
            Visitors are enthusiastic Computer Science and Artificial
            Intelligence bachelor and master students from all over the country.
            Over the past years, the conference has grown considerably.
            Where in 2017 the conference was visited by 450 students,
            we expect around 850 visitors in 2024.
            Various talks were held at the conference by speakers
            from both the business world and the academic world.
            The students have been challenged to dive into a specific topic
            from different perspectives.
            We want to inspire them and invite them to think further
            than their current views.
          </Paragraph>
        </Box>
        <Box sx={{
          marginBottom: '4rem', backgroundColor: 'primary.main', borderRadius: '8px', boxShadow: '0px 1px 10px rgba(1,1,1,0.15)', padding: '1rem',
        }}
        >
          <TypographyHeader variant="h3">
            About the committee
          </TypographyHeader>
          <img
            src="/committee_tiny.jpg"
            alt="Group"
            style={{ width: '100%' }}
          />
          <span style={{ fontStyle: 'italic' }}>
            F.l.t.r.: Joran, Rachel, Thomas, Merlijne, Sasha, Dionne, Charlotte, Pim.
          </span>
        </Box>

        <Box sx={{
          display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'primary.main', borderRadius: '8px', boxShadow: '0px 1px 10px rgba(1,1,1,0.15)', padding: '1rem', marginTop: '4rem',
        }}
        >
          <TypographyHeader variant="h3">Contact us</TypographyHeader>
          <Container maxWidth="xs" sx={{ textAlign: 'left' }}>
            <ContactInfoHeader variant="h5">General Email</ContactInfoHeader>
            <ContactInfoField>
              <Link
                sx={{ color: '#95C11F' }}
                href="mailto:inquiries@sustainabilit.snic.nl"
              >
                inquiries@sustainabilit.snic.nl
              </Link>
            </ContactInfoField>
            <ContactInfoHeader variant="h5">Business Email</ContactInfoHeader>
            <ContactInfoField>
              <Link
                sx={{ color: '#95C11F' }}
                href="mailto:partners@sustainabilit.snic.nl"
              >
                partners@sustainabilit.snic.nl
              </Link>
            </ContactInfoField>
            <ContactInfoHeader variant="h5">Address</ContactInfoHeader>
            <ContactInfoField sx={{ whiteSpace: 'pre-wrap' }}>
              <b>Via </b>
              t.a.v. SustainabilIT
              <br />
              Postbus 94214
              <br />
              1090GE, Amsterdam
            </ContactInfoField>
          </Container>
        </Box>
      </Container>
    </Box>
  );
}

export default AboutPage;
