import React from 'react';
import {
  Box, Button, colors, Container,
} from '@mui/material';
import { Camera, CameraAltRounded } from '@mui/icons-material';
import { Client, Partner } from '../clients/server.generated';
import ThemeComponent from '../components/frontpage/ThemeComponent';
import DateLocationComponent from '../components/frontpage/DateLocationComponent';
import { shuffleArray } from '../helpers/array';
//import TicketComponent from '../components/frontpage/TicketComponent';
import PartnersComponent from '../components/frontpage/PartnersComponent';
import TypographyHeader from '../components/layout/TypographyHeader';

function App() {
  const [partners, setPartners] = React.useState<Partner[] | null>(null);
  const [shuffledPartners, setShuffledPartners] = React.useState<Partner[] | null>(null);

  React.useEffect(() => {
    const client = new Client();
    client.getAllPartners()
      .then((p) => {
        setPartners(p);
        setShuffledPartners(shuffleArray(p));
      });
  }, []);

  if (!partners || !shuffledPartners) return null;

  return (
    <Box sx={{
      width: '100%', display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center',
    }}
    >
      <Container maxWidth="md">
        <Box sx={{
          boxShadow: '1px 1px 10px rgba(1,1,1,0.15)', padding: '2em', borderRadius: '10px', backgroundColor: 'white',
        }}
        >
          <TypographyHeader variant="h3">
            	Thank you!
          </TypographyHeader>
          <TypographyHeader variant="h4">
          Thank you for visiting SNiC 2024: SustainabilIT!
          </TypographyHeader>

          <Button variant="contained" onClick={() => window.open('https://www.flickr.com/gp/viafotocollectief/1c85SU2q9o')} startIcon={<CameraAltRounded />} sx={{ backgroundColor: '#95C11F', marginTop: '2em' }}>
            Photos
          </Button>
        </Box>

        <Box sx={{
          boxShadow: '1px 1px 10px rgba(1,1,1,0.15)', padding: '2em', borderRadius: '10px', backgroundColor: 'white', marginTop: '5em',
        }}
        >
          <TypographyHeader variant="h3">
            This year&apos;s theme: SustainabilIT!
          </TypographyHeader>
          <ThemeComponent />
        </Box>

        <Box sx={{
          boxShadow: '1px 1px 10px rgba(1,1,1,0.15)', padding: '2em', borderRadius: '10px', backgroundColor: 'white', marginTop: '5em',
        }}
        >
          <TypographyHeader variant="h3">
          Where & when:
          </TypographyHeader>
          <DateLocationComponent />
        </Box>

        {/* <Box sx={{
          boxShadow: '1px 1px 10px rgba(1,1,1,0.15)', padding: '2em', marginTop: '50px', borderRadius: '10px', backgroundColor: 'white',
        }}
        >
          <TypographyHeader variant="h3">
            Get your tickets!
          </TypographyHeader>
          <TicketComponent />
        </Box> */}
        <PartnersComponent partners={partners}/>
      </Container>
    </Box>
  );
}

export default App;
